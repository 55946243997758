<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h2 class="layout-modal__title">{{ title }}</h2>
      <p class="layout-modal__subtitle">{{ subtitle }}</p>
    </template>

    <template v-if="loading">  
      <div class="view-user-nft__loader">
        <ui-loader />
      </div>
    </template>

    <!-- Error -->
    <section 
      class="view-claim-auth__login flow" 
      v-if="hasError">
      <ui-banner
        :description="error.description"
        :title="error.title"
        :intent="$pepper.Intent.DANGER"
      />
    </section>

    <template v-if="!loading">  
      <div 
        class="view-user-nft__body" 
        v-if="nft">
        <ui-banner 
          :intent="$pepper.Intent.DANGER"
          :title="error.message"
          v-if="error && error.status && error.status === 404"
        />
        
        <div class="view-user-nft__row">
          <div 
            class="view-user-nft__block -description"
            v-if="description">
            <p
              class="view-user-nft__description" 
              v-html="nl2br(description)"
            ></p>
          </div>

          <ui-asset 
            class="view-user-nft__asset"
            :value="asset"
          />
        </div>

        <ui-banner
          class="view-user-nft__warn"
          :description="!hasAddressTransfer ? $t('user-portal.nft_is_peer_transfered_descr') : $t('user-portal.nft_is_peer_transfered_per_address_descr')"
          :title="$t('user-portal.nft_is_peer_transfered_title')"
          :intent="$pepper.Intent.INFO"
          v-if="hasPeerTransfer"
        />

        <nft-triggers
          :triggers="triggers"
          v-if="triggers.length > 0"
        />

        <nft-vouchers
          :vouchers="vouchers"
          :generated="generatedVouchers"
          v-if="hasVouchers"
        />

        <ui-nft-attributes
          :values="attributes"
          v-if="attributes && attributes.length > 0"
        />
      </div>

      <modal-transfer-nft
        :nft="selected"
        :visible="showTransfer"
        @close="onCloseTransfer"
        @confirm="reset"
        v-if="showTransfer"
      />
    </template>

    <template #footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        :disabled="claiming"
        @click="back"
      >{{ $t('user-portal.action_close') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onTransferClick"
        v-if="allowPeerTransfer && !isTransfer && !hasError && !hasPeerTransfer && !loading && !hasAddressTransfer"
      >{{ $t('user-portal.action_peer_transfer') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onTransferCancel"
        v-if="allowPeerTransfer && !isTransfer && !hasError && hasPeerTransfer && !loading && !hasAddressTransfer"
      >{{ $t('user-portal.action_cancel_peer_transfer') }}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        :disabled="claiming"
        :loading="claiming"
        @click="onClaim"
        v-if="isTransfer"
      >{{ $t('user-portal.action_claim') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

import MixinText from '@/helpers/text'

import LayoutModal from '@/components/layouts/modal.vue'
import ModalTransferNft from '@/modules/user/components/modals/transfer.vue'
import NftVouchers from '@/modules/user/components/data/nft-vouchers.vue'
import NftTriggers from '@/modules/user/components/data/nft-triggers.vue'
import UiAsset from '@/components/ui/asset.vue'
import UiBanner from '@/components/ui/banner.vue'
import UiNftAttributes from '@/components/ui/nft-attributes.vue'

export default {
  name: 'ModalNft',

  components: {
    LayoutModal,
    ModalTransferNft,
    NftVouchers,
    NftTriggers,
    UiAsset,
    UiBanner,
    UiNftAttributes,
  },

  inject: [
    '$nft',
    '$user',
    '$wallet',
  ],

  mixins: [
    MixinText,
  ],

  props: {
    backRoute: {
      type: Object,
      default: () => { return { name: 'sayl-user-portal.wallet' } },
    },

    isTransfer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      category: null,
      contract: null,

      error: {
        status: null,
        title: null,
      },
      hasError: false,

      loading: true,
      nft: null,
      claiming: false,
      vouchers: [],

      selected: null,
      showTransfer: false,
      generatedVouchers: [],

      nftPeerTransfer: null,
      cancelling: false,

      triggers: [],
    }
  },

  computed: {
    allowPeerTransfer() {
      let bootstrap = SaylStore.bootstrap()
      let venly = bootstrap.venly

      let allowTransfer = this.$basil.get(this.contract, 'allow_user_transfer', true)

      return venly.allow_meta_transaction === true && allowTransfer === true
    },

    asset() {
      return this.$basil.get(this.nft, 'assetUrl', null)
    },

    attributes() {
      return this.$basil.get(this.nft, 'attributes', [])
    },

    attrs() {
      return {
        classes: {
          'view-user-nft': true
        },
        visible: true,
      }
    },

    description() {
      return this.$basil.get(this.nft, 'description', null)
    },

    hasAddressTransfer() {
      return this.$basil.get(this.nftPeerTransfer, 'status', 1) === 40
    },

    hasPeerTransfer() {
      return !this.$basil.isNil(this.nftPeerTransfer)
    },

    hasVouchers() {
      let ret = Object.keys(this.generatedVouchers)
      return ret.length > 0 || (this.vouchers && this.vouchers.length > 0)
    },

    listeners() {
      return {
        close: this.back
      }
    },

    nfts() {
      let ret = this.$wallet.nfts || []

      if(this.isTransfer && this.$route.params.transfer) {
        ret = this.$wallet.transfers || []
      }

      return ret
    },

    subtitle() {
      return `#${this.$basil.get(this.nft, 'id', null)}`
    },

    title() {
      let ret = this.$t('user-portal.loading')

      if(!this.loading) {
        if(this.nft != null) {
          ret = this.$basil.get(this.nft, 'name', null)
        }

        if(ret == null || this.nft == null) {
          ret = this.$t('user-portal.nft_error')
        } 
      }

      return ret
    }
  },

  methods: {
    back() {
      if(this.claiming) {
        return
      }
      this.$router.push(this.backRoute).catch(() => {})
    },

    async onClaim() {
      try {
        this.claiming = true
        await this.$nft.claim({ nft_transfer_id: this.$route.params.transfer })
        this.claiming = false

        this.$notification({
          title: this.$t('user-portal.nft_claimed_success'), 
          message: this.$t('user-portal.nft_claimed_success_message'), 
          type: 'success'
        })
        
        this.$emit('refresh')
        this.back()
      } catch(e) {
        this.claiming = false
        this.$notification({
          title: this.$t('user-portal.nft_claimed_error'), 
          message: this.$t('user-portal.nft_claimed_error_message'), 
          type: 'error'
        })

        $console.error(e)
      }
    },

    onTransferCancel() {
      this.$confirm({
        title: this.$t('user-portal.nft_peer_transfer_cancel_title'),
        description: this.$t('user-portal.nft_peer_transfer_cancel_description'),
        primaryAction: this.$t('user-portal.confirm_action'),
        secondaryAction: this.$t('user-portal.close_action'),
        onPrimary: async () => {
          try {
            this.cancelling = true
            await this.$nft.cancelPeerTransfer({ id: this.$basil.get(this.nftPeerTransfer, 'id') })
            this.reset()
          } catch(e) {
            this.$notification({
              title: this.$t('user-portal.nft_peer_transfer_cancel_error'),
              type: 'error'
            })
          } finally {
            this.cancelling = false
          }
        }
      })
    },

    onTransferClick() {
      this.selected = this.nft
      this.showTransfer = true
    },

    onCloseTransfer() {
      this.showTransfer = false
      this.selected = null
    },  

    async reset() {
      try {
        this.onCloseTransfer()
        this.loading = true

        let nft = this.nfts.find((n) => {
          let nn = this.isTransfer ? this.$basil.get(n, 'nft', null) : n
          return this.$basil.get(nn, 'contract.address', null) == this.$route.params.contract &&
            this.$basil.get(nn, 'id', null) == this.$route.params.id
        })

        if(nft) {
          this.nft = this.isTransfer ? nft.nft : nft

          if(this.attributeCategories && this.attributeCategories.length > 0) {
            this.category = this.attributeCategories[0].value
          }

          try {
            let { 
              contract,
              vouchersSorted,
              nftPeerTransfer,
              triggers,
            } = await this.$wallet.getNftData({ 
              address: this.$basil.get(this.nft, 'contract.address'), 
              serial: this.$basil.get(this.nft, 'id') 
            })

            this.contract = contract
            this.generatedVouchers = vouchersSorted
            this.nftPeerTransfer = nftPeerTransfer
            this.triggers = triggers

            this.vouchers = await this.$wallet.getVouchersForOneNft({ 
              address: this.$basil.get(this.nft, 'contract.address'), 
              serial: this.$basil.get(this.nft, 'id')
            })
          } catch(e) {
            $console.error(e)
          } 
          return 
        } 

        this.error = {
          status: 404,
          title: this.$t('user-portal.nft_not_found')
        }
        this.hasError = true
        $console.error(this.error)
      } catch(e) {
        this.error = {
          status: 500,
          title: this.$t('user-portal.nft_unexpected_error')
        }
        this.hasError = true
        $console.error(e)
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.reset()
  },
}
</script>