<template>
  <div class="ui-asset">
    <img 
      class="ui-asset__content"
      :src="value" 
      v-if="type === 'image'"
    />

    <video
      class="ui-asset__content"
      autoplay
      loop
      muted
      :src="value"
      v-if="type === 'video'"
    />
  </div>
</template>

<script>
export default {
  name: 'UiAsset',

  inject: ['$utils'],

  props: {
    value: { 
      required: true,
      type: String, 
    },
  },

  data() {
    return {
      loading: true,
      _type: null,
    }
  },

  computed: {
    type() {
      if(this.loading) {
         return
      }

      if(this._type && this._type.includes('video')) {
        return 'video'
      }

      if(this._type && this._type.includes('image')) {
        return 'image'
      }

      return 'image'
    },
  },

  methods: {
    async reset() {
      // try {
      //   this.loading = true
      //   this._type = await this.$utils.getImageType(this.value)
      // } catch(e) {
      //   $console.error(e)
      // } finally {
      //   this.loading = false
      // }
      this.loading = false
    }
  },

  mounted() {
    this.reset()
  }
}
</script>