<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h2 class="modal-user-transfer__title">{{ $t('user-portal.nft_peer_transfer_title') }}</h2>
    </template> 

    <div 
      class="view-user-nft__body flow"
      @keyup="onKeyUp">
      <ui-banner
        :description="transferError.description"
        :title="transferError.title"
        :intent="$pepper.Intent.DANGER"
        v-if="hasError"
      />

      <forms-input
        :disabled="showPins"
        :errors="getErrors('destination')"
        :placeholder="$t('user-portal.nft_peer_transfer_destination_placeholder')"
        required
        @input="onRemoveError('destination')"
        v-model="destination"
      >{{ $t('user-portal.nft_peer_transfer_destination') }}</forms-input>

      <forms-pins
        :errors="getErrors('pin')"
        :key="keypins"
        :loading="loading"
        :steps="['transfer_pin']"
        @confirm="() => onTransfer(null, true)"
        v-model="pins"
        v-if="showPins"
      />
    </div>

    <template #footer>
      <div class="modal-user-transfer__backs">
        <actions-button
          :appearance="$pepper.Appearance.DEFAULT"
          :disabled="loading"
          @click="onDiscardPins"
          v-if="showPins"
        >{{ $t('user-portal.action_previous') }}</actions-button>

        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          :disabled="loading"
          @click="back"
        >{{ $t('user-portal.action_close') }}</actions-button>  
      </div>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        :disabled="loading"
        :loading="loading"
        @click="onTransfer"
        v-if="!showPins"
      >{{ $t('user-portal.action_transfer') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import MixinErrors from '@/helpers/errors'

import FormsPins from '@/components/forms/pins.vue'
import LayoutModal from '@/components/layouts/modal.vue'
import UiBanner from '@/components/ui/banner.vue'

export default {
  name: 'ModalTransferNFT',

  components: {
    FormsPins,
    LayoutModal,
    UiBanner,
  },

  inject: [
    '$nft',
    '$user'
  ],

  mixins: [
    MixinErrors,
  ],

  props: {
    nft: {
      type: Object,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      destination: null,
      regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      errors: {},
      
      transferError: {
        description: null,
        status: null,
        title: null,
      },
      hasError: false,

      pins: {
        transfer_pin: null
      },

      showPins: false,
      keypins: 1,
      loading: false,
    }
  },

  computed: {
    attrs() {
      return {
        closable: false,
        classes: {
          'modal-user-transfer': true
        },
        position: 'middle-center',
        size: 's',
        visible: true,
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    walletIsCustodial() {
      return this.user.walletIsCustodial ?? false
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    onDiscardPins() {
      this.errors = {}
      this.destination = null
      this.showPins = false
      this.pins.transfer_pin = null
      this.keypins = this.keypins + 1
    },

    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onTransfer()
      }
    },

    async onTransfer(ev, pin = false) {
      try {
        this.loading = true
        
        if(!this.destination.match(this.regexEmail) && pin === false && !this.walletIsCustodial) {
          this.showPins = true
          return 
        } 
          
        let item = { destination: this.destination }

        if(pin === true && this.showPins === true) {
          item.pin = this.pins.transfer_pin.join('')
        }

        await this.$nft.sendPeerTransfer({ 
          address: this.$route.params.contract,
          item,
          serial: this.$route.params.id,
        })

        this.$notification({
          title: this.$t('user-portal.nft_peer_transfer_sent'),
          type: 'success'
        })
        this.$emit('confirm')
      } catch(e) {
        if(e.status && e.status === 422) {
          this.handleErrors(e)
        } else if (e.status && e.status === 423) {
          let message = this.$basil.get(e, '_items.response.data.message')
          this.transferError = {
            description: this.$t(`user-portal.${message ? message : 'nft_peer_transfer_error_description'}`),
            status: 423,
            title: this.$t('user-portal.nft_peer_transfer_error_423'),
          }
          this.hasError = true
        } else {
          this.transferError = {
            description: this.$t('user-portal.nft_peer_transfer_error_description'),
            status: 404,
            title: this.$t('user-portal.nft_peer_transfer_error'),
          }
          this.hasError = true
        }

        this.$notification({
          title: this.$t('user-portal.nft_peer_transfer_error_title'),
          type: 'error'
        })

        this.pins.transfer_pin = null
        $console.error(this.error)
      } finally {
        this.loading = false
        this.keypins = this.keypins + 1
      }
    },

    reset() {
      this.transferError = {
        description: null,
        status: null,
        title: null,
      }
      this.hasError = false
    },
  },

  mounted() {
    this.reset()
  }
}
</script>