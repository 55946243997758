<template>
  <div 
    class="forms-pins flow" 
    :class="{
      '--loading': loading
    }"
    :key="step"
    @keyup="onKeyup">

    <div 
      class="forms-pins__loader"
      v-if="loading">
      <ui-loader size="l"/>
    </div>

    <div class="forms-pins__label" v-if="labeled">{{ label }}</div>

    <div 
      class="forms-pins__bubbles"
      v-if="!iLoading">
      <div 
        class="forms-pins__bubble"
        :class="{ '--filled': isFilled(v) }"
        :key="`bubble_${v}`"
        v-for="v in length"></div>
    </div>

    <div 
      class="forms-pins__keys"
      v-if="!iLoading">
      <div 
        class="forms-pins__key" 
        :class="{ 
          '--success': key.intent === 'success',
          '--disabled': key.disabled === true,
        }"
        :key="key.value"
        @click="() => onKeyClick(key)"
        v-for="key in keys">
        <p 
          class="forms-pins__value" 
          v-if="key.label && !key.icon"
        >{{ key.value }}</p>
        
        <ui-icon 
          class="forms-pins__icon" 
          :glyph="key.icon" 
          v-if="key.icon"
        />
      </div>
    </div>

    <div 
      class="forms-pins__errors" 
      v-if="hasErrors">
      <div 
        class="forms-pins__error" 
        v-for="error in errors">{{ $t(error) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormsPin',

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false,
    },

    labeled: {
      type: Boolean, 
      default: true,
    },

    length: {
      type: Number,
      default: 6,
    },

    prefix: {
      type: String,
      default: null
    }, 

    steps: {
      type: Array,
      default: () => ['pin']
    },

    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      iLoading: true,
      step: null,
      pins: {},
    }
  },

  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0
    },

    keys() {
      return [
        { value: 1, key: 'Digit1', label: '1', disabled: this.disabled, },
        { value: 2, key: 'Digit2', label: '2', disabled: this.disabled, },
        { value: 3, key: 'Digit3', label: '3', disabled: this.disabled, },
        { value: 4, key: 'Digit4', label: '4', disabled: this.disabled, },
        { value: 5, key: 'Digit5', label: '5', disabled: this.disabled, },
        { value: 6, key: 'Digit6', label: '6', disabled: this.disabled, },
        { value: 7, key: 'Digit7', label: '7', disabled: this.disabled, },
        { value: 8, key: 'Digit8', label: '8', disabled: this.disabled, },
        { value: 9, key: 'Digit9', label: '9', disabled: this.disabled, },
        { value: -1, key: 'Backspace', icon: 'backspace', disabled: this.disabled || this.pins[this.step].length === 0 },
        { value: 0, key: 'Digit0', label: '0', disabled: this.disabled, },
        { value: 99, key: 'Enter', icon: 'check', intent: 'success', disabled: this.disabled || this.pins[this.step].length < this.length },
      ]
    },

    label() {
      return this.step && this.$t(`user-portal.pins_label_${this.prefix ? this.prefix + '_' : ''}${this.step}`)
    },
  },

  methods: {
    isFilled(index) {
      return this.step && this.pins && this.pins[this.step] && this.pins[this.step][index - 1] != null
    },

    onKeyClick(key) {
      this.$emit('input')
      if(!key.disabled && key.value === 99) {
        this.next()
        return
      }

      if(!key.disabled && key.value === -1 && this.pins[this.step].length > 0) {
        this.pins[this.step].pop()
        return 
      }

      if(this.pins[this.step].length < this.length) {
        this.pins[this.step].push(key.value)
        return
      }
    },

    onKeyup(event) {
      let v = this.keys.find(k => k.key === event.code)

      if(v && !v.disabled) {
        this.onKeyClick(v)
      }
    },

    next() {
      this.$emit('change', this.pins)

      let currentStepIndex = this.steps.indexOf(this.step)

      if(currentStepIndex < this.steps.length - 1) {
        this.step = this.steps[currentStepIndex + 1]
        return
      }

      this.$emit('confirm', this.pins)
    },

    reset() {
      this.iLoading = true
      Object.keys(this.value).forEach(key => {
        this.$set(this.pins, key, this.value[key] != null ? this.value[key] : [])
      })
      this.step = this.steps[0]
      this.iLoading = false
    }
  },

  mounted() {
    this.reset()
    window.addEventListener('keyup', this.onKeyup)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup)
  }
}
</script>